<template>
  <div id="app">
    <!-- header needs to stay above the flex box -->
    <CTSHeader :userData="userData" :authClient="ctsAuthClient" />
    <div class="contentSeparator">
      <Navigation :userData="userData" v-bind:navigation="navigation" v-on:selectItem="selectNav" />
      <ContentPane :userData="userData"/>
    </div>

    <!-- <img alt="Community logo" src="./assets/logovdg.png"> -->

    <!-- 'navigation' in quotes is coming from data -->
  </div>
</template>

<script>
import Navigation from "./components/Navigation";
import CTSHeader from "./components/layout/CTSHeader";
import ContentPane from "./components/ContentPane";
import CtsAuthLibrary from './js/ctsAuthLibrary';
import * as Constants from './js/constants';

export default {
  name: "App",
  components: {
    Navigation,
    CTSHeader,
    ContentPane,
  },
  data() {
    //  The { must be on the same line as the return, duh
    return {
      // will be replaced by configuration file at some point
      navigation: [
        {
          title: Constants.HOME,
        },
        {
          title: Constants.GATE,
          subNavigationItems: [
            {
              title: Constants.ENTRY_LOG,
            },
            {
              title: Constants.KEYS,
            },
            {
              title: Constants.FREQUENT_VISITORS,
            },
            {
              title: Constants.BLOCKED_LIST,
            },
            {
              title: Constants.VENDORS,
            },
          ]
        },
        {
          title: Constants.INCIDENTS,
        },
        {
          title: Constants.USERS,
          subNavigationItems: [
            {
              title: Constants.RESIDENT_DIRECTORY,
            },
            {
              title: Constants.VEHICLES,
            },
            {
              title: Constants.PETS,
            },
            {
              title: Constants.VENDORS,
            }
          ]
        },
        {
          title: Constants.MAINTENANCE,
        },
        {
          title: Constants.COMMUNITYANDCOMMUNICATION,
        },
        {
          title: Constants.ACCOUNTING,
        },
        {
          title: Constants.ANALYTICS,
        },
        {
          title: Constants.SUPPORT,
        },
      ],
      userData: {},
      ctsAuthClient: null
    };
  },
  created: function () {

    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    console.log("Access Code", code);

    var ctsAuthClient = new CtsAuthLibrary();
    this.ctsAuthClient = ctsAuthClient

    ctsAuthClient.authFlow()
      .then(async (data) => {
        console.log("Returned Auth Data", data);
        this.userData = data
        await ctsAuthClient.retrieveConfig();

        //Determine if a feature is enabled
        var featureId = "users_modal_admin"
        //var featureId = "home_alert_message"
        if (ctsAuthClient.featureFlags[featureId]) {
          //Feature Code
          console.log("Feature ", featureId, " is enabled")
        }
        else {
          //Disable Feature Code
          console.log("Feature ", featureId, " is not enabled")
          //this.navigation.splice(4, 1);
        }

        console.log("CTS Auth Flow Result", data);
        console.log("CTS Role", ctsAuthClient.userData["custom:cts_role"]);

      })
      .catch((err) => {
        console.log("Error CTS Client", err);
      });
  },
  methods: {
    selectNav(title) {
      var selTitle = title;
      console.log("User selected " + title + " item in directory");
      console.log(selTitle);

      for (var x = 0; x < this.navigation.length; x++) {
        console.log(this.navigation[x]);

        if (this.navigation[x].title !== selTitle) {
          this.navigation[x].clicked = false;
        } else {
          this.navigation[x].clicked = true;
        }
      }
    },
  },
};
</script>

<style scoped src="./css/gatewatch-styles.css"></style>
