<template>
    <div class="camsContainer">
        <ContentHeader name="Reporting" :subHeader="subHeader" />
        <div class="widget-container">
            <Widget class="widget" v-for="widget in widgets" :key="widget.title" :widget="widget" />
        </div>

    </div>

</template>


<script>
import ContentHeader from '../ContentHeader.vue';
import Widget from './pageComps/widget/Widget.vue';

export default {
    name: "Reporting",
    components: {
        ContentHeader,
        Widget
    },
    props: ['userData'],
    created() {

    },
    methods: {
        defaultTableCallback(item) {
            console.log("item", item)
            alert(`Clicked a widget item - ${item.title}`)
        }
    },
    watch: {
        userData: function (newVal, oldVal) {
            console.log("userData changed: ", newVal, " | was: ", oldVal);
            this.localUserData = newVal
            this.subHeader = `Here's what's going on today in ${newVal['custom:cts_property_id']}!`
        }
    },
    data() {
        console.log("Reporting User data", this.$props.userData)
        return {
            localUserData: {},
            subHeader: `Users can run reports for any data point needed in Community Tech.`,
            widgets: [
                {
                    title: "Incoming Visitors",
                    type: "widgetTable",
                    data: [
                        {
                            title: "Richard Dale",
                            subtitle: "Visiting Jason Quintana | 624 SW 180th Ave. Pembroke Pines, FL | 954-394-2449",
                            details: "Expected Arrival: 8:30 - 10:30",
                            action: (item) => this.defaultTableCallback(item)
                        },
                        {
                            title: "Richard Dale",
                            subtitle: "Visiting Jason Quintana | 624 SW 180th Ave. Pembroke Pines, FL | 954-394-2449",
                            details: "Expected Arrival: 8:30 - 10:30",
                            action: (item) => this.defaultTableCallback(item)

                        },
                        {
                            title: "Richard Dale",
                            subtitle: "Visiting Jason Quintana | 624 SW 180th Ave. Pembroke Pines, FL | 954-394-2449",
                            details: "Expected Arrival: 8:30 - 10:30",
                            action: (item) => this.defaultTableCallback(item)
                        },
                        {
                            title: "Richard Dale",
                            subtitle: "Visiting Jason Quintana | 624 SW 180th Ave. Pembroke Pines, FL | 954-394-2449",
                            details: "Expected Arrival: 8:30 - 10:30",
                            action: (item) => this.defaultTableCallback(item)

                        },
                        {
                            title: "Richard Dale",
                            subtitle: "Visiting Jason Quintana | 624 SW 180th Ave. Pembroke Pines, FL | 954-394-2449",
                            details: "Expected Arrival: 8:30 - 10:30",
                            action: (item) => this.defaultTableCallback(item)
                        },
                        {
                            title: "Richard Dale",
                            subtitle: "Visiting Jason Quintana | 624 SW 180th Ave. Pembroke Pines, FL | 954-394-2449",
                            details: "Expected Arrival: 8:30 - 10:30",
                            action: (item) => this.defaultTableCallback(item)
                        },
                    ]
                },
                {
                    title: "Incidents Reported Today",
                    type: "widgetBarChart",
                    data: [],
                },
                {
                    title: "HOA Fees",
                    type: "widgetPieChart",

                },
                {
                    title: "Incidents Reported Today",
                    type: "widgetBarChart",
                    data: [],
                },
                {
                    title: "HOA Fees",
                    type: "widgetPieChart",

                },
                {
                    title: "Incidents Reported Today",
                    type: "widgetBarChart",
                    data: [],
                },
                {
                    title: "HOA Fees",
                    type: "widgetPieChart",

                },
                {
                    title: "Your To-Do's",
                    type: "widgetTable",
                    data: [
                        {
                            title: "HOA Dues Overdue",
                            subtitle: "Jason Quintana",
                            details: "Amount Due: $120",
                            action: (item) => this.defaultTableCallback(item)

                        },
                        {
                            title: "New Vehicle Tag Requested",
                            subtitle: "Richard Dale | 2024 Acura TSX | VJ7X61",
                            details: "Due Date: Today",
                            action: (item) => this.defaultTableCallback(item)

                        },
                        {
                            title: "Update Resident Information",
                            subtitle: "Daniel Sypioe | Update Secondary Email",
                            details: "Due Date: Tomorrow",
                            action: (item) => this.defaultTableCallback(item)

                        },
                        {
                            title: "Facility Request",
                            subtitle: "Clubhouse Bathroom Out of Order",
                            details: "Due Date: 11/30/2024",
                            action: (item) => this.defaultTableCallback(item)

                        },
                        {
                            title: "Resident Violation",
                            subtitle: "Saud Hasnain | Pressure Cleaning Violation",
                            details: "Amount Due: $50",
                            action: (item) => this.defaultTableCallback(item)

                        },
                        {
                            title: "HOA Dues Overdue",
                            subtitle: "Jason Quintana",
                            details: "Amount Due: $120",
                        },
                    ]
                },
            ],
        }
    },
};
</script>

<style scoped>
.widget-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 16px;
}

.widget {
    width: 32%;
    min-width: 350px;
    max-height: 300px;
    display: block;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    text-align: left;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 25px;
    overflow-y: auto;
    margin-right: 8px;

}
</style>