<template>
  <div class="camsContainer">
    <GateModal />
    <div class="nonGraphContainer">
      <div class="pageHeader">
        <ContentHeader :name=this.headerName :subHeader="subHeader" />
        <button @click="(event) => handleButtonPress(event)">Block Visitor</button>
      </div>
      <Insights :cardInfo="cardInfo" :callback="updateFiltersForSearchBasedOnCard" />
    </div>


    <div class="currentView">
      <div>
        <div class="dataTableSelectorGroup">
          <div class="dataTableSelector">
            <SearchBar class="gate-search" v-bind:search="search"
              v-bind:searchCallback="(data) => { this.performSearch(data) }" placeholder="Enter Search" />
            <svg class="filterIcon" width="32" height="26" viewBox="0 0 32 26" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="4" fill="#699991" rx="2" />
              <rect x="8" y="22" width="16" height="4" fill="#699991" rx="2" />
              <rect x="4" y="11" width="24" height="4" fill="#699991" rx="2" />
            </svg>
          </div>
        </div>
        <DataTable v-bind:tableData="tableData" v-bind:configuration="selectedItem" v-bind:openModalCallback="() => {
          handleModal(selectedItem);
        }
          " />
      </div>
    </div>
  </div>
</template>

<script>
import ContentHeader from "../ContentHeader";
import * as Constants from "../../js/constants";
import EventBus from "../../eventBus";
import DataTable from "./pageComps/DataTable.vue";
import SearchBar from "./pageComps/Search.vue";
import GateModal from "../modals/GateModal.vue";
import Insights from "../layout/Insights.vue";

export default {
  name: "GatePage",
  components: {
    ContentHeader,
    DataTable,
    GateModal,
    SearchBar,
    Insights
  },
  data() {
    return {
      showKeys: true,
      showIncidents: false,
      selectedItem: Constants.GATE,
      filterStartDate: null,
      filterEndDate: null,
      defaultStartDate: null,
      defaultEndDate: null,
      headerName: Constants.GATE,
      subHeader: "Here's a log of all keys sent for your Community.",
      defaultFilters: {
        residentName: null,
        visitorName: null,
        subdivision: null,
        status: null,
        startTime: null,
        endTime: null
      },
      filters: {},
      tableData: [],
      search: "Gate Search",
      cardInfo: [
        {
          title: "Total Keys Sent",
          value: "70",
          filters: {

          }
        },
        {
          title: "Total Keys Validated",
          value: "30",
          filters: {
            residentName: "",
            visitorName: "",
            subdivision: "",
            status: "VALIDATED",
            startTime: null,
            endTime: null
          }
        },
        {
          title: "Total Keys Pending",
          value: "3",
        },
        {
          title: "Total Keys Denied",
          value: "1",
        },
        {
          title: "Party Keys Created",
          value: "30",
          filters: {
            residentName: "",
            visitorName: "",
            subdivision: "",
            status: "CREATED",
            startTime: null,
            endTime: null
          }
        }
      ],
    };
  },
  created() {
    this.changeView(this.selectedItem);
    EventBus.$on("keys", this.changeView);
    EventBus.$on("incidents", this.changeView);

    var date = new Date();
    this.defaultEndDate = date.toISOString().split("T")[0];
    var startDate = new Date(date);
    startDate.setDate(date.getDate() - 7);
    //this.defaultStartDate = startDate.toISOString().split('T')[0]
    this.defaultStartDate = "2019-06-06";
    this.filters = this.defaultFilters

    this.performSearch("Richard").then((apiData) => {
      console.log("Finished getting and setting API Data", apiData)
      this.search = ""
    });
  },
  watch: {
    search() {
      this.filters = this.defaultFilters
    },
  },
  methods: {
    openGateFiletersModal() {
      console.log("Opening Gate Filters Modal");
    },
    homeChangeText() {
      console.log("Home knows the button was clicked");
    },
    changeView(name) {
      console.log("Changing View from ", this.selectedItem, " to ", name);
    },
    performSearch(data) {
      this.search = data
      let today = new Date().toISOString().split('T')[0]
      let url = `https://2r7ags60v2.execute-api.us-east-2.amazonaws.com/stage/nautica2/keys/search?resident_name=${this.filters.residentName ?? data}&visitor_name=${this.filters.visitorName ?? data}&time_start=${this.filters.startTime ?? "2022-01-02"}&time_end=${this.filters.endTime ?? today}&status=${this.filters.status ?? data}&subdivision=${this.filters.subdivision ?? data}`
      return new Promise((resolve, reject) => {
        fetch(
          url,
          {
            method: `GET`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log("Search Data", data);
            this.tableData = data.data
            resolve(data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateFiltersForSearchBasedOnCard(card) {
      this.filters = card.filters ?? this.defaultFilters
      this.performSearch(this.search).then(() => {
      })
    },
    handleButtonPress(event) {
      console.log("event", event)
      alert(event.target.innerHTML)
    },
    openGateModal() {
      this.$modal.show("gate-modal");
    },
    openUsersModal() {
      this.$modal.show("users-modal");
    },
    handleModal(configuration) {
      switch (configuration) {
        case Constants.GATE: {
          this.openGateModal();
          break;
        }
        case Constants.INCIDENTS: {
          console.log("Opening Incidents Modal");
          break;
        }
        case Constants.USERS: {
          this.openUsersModal();
          break;
        }
      }
    },
  },
};
</script>

<style scoped>
.dataTableSelectorGroup {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.dataTableSelector:nth-child(3n) {
  margin-right: 0px;
}

.selectorContent {
  width: 100%;
  color: white;
  font-size: 28px;
}

.selectorContent::placeholder {
  width: 100%;
  color: white;
  font-size: 28px;
}

.filterIcon {
  margin-right: 16px;
}

input[type="text"]:focus {
  background-color: lightblue;
}

input[type="date"]:focus {
  outline: none;
  border: 0;
  appearance: none;
  border-width: 0px;
  border: none;
}
</style>