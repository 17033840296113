<template>
    <div>
        <h3 class="widget-title">{{ widget.title }}</h3>
        <div v-if="widget.type == 'widgetTable'">
            <WidgetTable :tableData="widget" class="widget-content" />
        </div>
        <div v-else-if="widget.type == 'widgetBarChart'">
            <WidgetBarChart :chartData="widget" class="widget-content" />
        </div>
        <div v-else-if="widget.type == 'widgetPieChart'">
            <WidgetPieChart :chartData="widget" class="widget-content" />
        </div>
    </div>
</template>

<script>
import WidgetBarChart from './WidgetBarChart.vue';
import WidgetPieChart from './WidgetPieChart.vue';
import WidgetTable from './WidgetTable.vue';

export default {
    name: "Widget",
    components: {
        WidgetTable,
        WidgetBarChart,
        WidgetPieChart
    },
    props: ["widget"],
    created() {

    },
    methods: {

    },
    data() {
        return {
        }
    }
};
</script>

<style>

.widget {
    width: 49%;
    min-width: 350px;
    max-height: 300px;
    display: block;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    text-align: left;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 25px;
    overflow-y: auto;

}

.widget-title {
    width: 100%;
    background-color: inherit;
    padding-top: 16px;
    border-bottom: 1px solid black;
    max-lines: 1;
    text-overflow: ellipsis;
    white-space: no-wrap;
    position: sticky;
    top: 0;
}

.widget-content {
    width: 100%;
    height: calc(100% - 16px);
}
</style>