<template>
  <modal :name="modalName" transition="fade" width="700" height="auto" draggable="true" @before-open="beforeOpen"
    :adaptive="true">
    <div class="modal-container">
      <div class="modal-header">
        <h3>Create Visitor</h3>
        <button @click=close>X</button>
      </div>
      <div class="modal-content">
        <div>
          <div v-if="useSeparateNameInput" class="modal-intro-information">
            <label>Visitor First Name*</label>
            <input ref="visitor-first-name" type="text" placeholder="First Name"
              class="modal-incident-title visitor-modal-title" v-model="visitorFirstName" />
            <label>Visitor Last Name*</label>
            <input ref="visitor-last-name" type="text" placeholder="Last Name"
              class="modal-incident-title visitor-modal-title" v-model="visitorLastName" />
          </div>
          <div v-else class="modal-intro-information">
            <label>Visitor Name:</label>
            <input ref="title" type="text" placeholder="Visitor Name" class="modal-incident-title visitor-modal-title"
              v-model="title" />
          </div>
        </div>
        <div class="modal-create-data">
          <div class="modal-create-data-item">
            <p>Resident Name*</p>
            <div v-if="!displaySearchResults">
              <div ref="resident-search" v-if="residentName == ''">
                <SearchBar class="searchBar resident-search-bar" searchIcon="true" placeholder="Search Residents"
                  inputStyle="font-size: 14px; text-align: center"
                  v-bind:searchCallback="(data) => { this.performResidentSearch(data) }" />
              </div>
              <button v-else ref="resident" @click="{ residentName = '' }"> {{ residentName }}</button>

            </div>
            <div v-else>
              <button ref="resident" @click="displaySearchResults = !displaySearchResults"> {{ "Search Results"
                }}</button>
              <ul @mouseleave="displaySearchResults = false">
                <li v-for="result in searchResults" :key="result['uuid']" @click="setResidentName(result)">
                  {{ `${getResidentDisplayName(result)}` }}
                </li>
              </ul>
            </div>

          </div>
          <div class="modal-create-data-item">
            <p>Visitor Type*</p>
            <div>
              <div v-if="showTypes">
                <ul @mouseleave="showTypes = false">
                  <li v-for="type in types" :key="type" @click="setType(type)">
                    {{ type }}
                  </li>
                </ul>
              </div>
              <button v-else ref="type" @click="showTypes = !showTypes"> {{ selectedType == "" ? "Select Visitor Type" :
                selectedType }}</button>
            </div>
          </div>
          <div class="modal-create-data-item">
            <p>License Plate Number</p>
            <div>
              <input ref="license-plate" type="text" placeholder="Visitor License Plate Number"
                class="visitor-modal-title" v-model="licensePlate" />
            </div>
          </div>
          <div class="modal-create-data-item">
            <p>Visitor Phone Number</p>
            <div>
              <input ref="visitor-phone-number" type="text" placeholder="Visitor Phone Number"
                class="visitor-modal-title" v-model="visitorPhoneNumber" />
            </div>
          </div>
          <div class="modal-create-data-item">
            <p>Key Expiration Time</p>
            <div>
              <!-- <input ref="visitor-phone-number" type="datetime-local" placeholder="Vistor Expiration Time" class="visitor-modal-title" v-model="visitorExpiration" /> -->
              <input ref="visitor-expiration" type="date" placeholder="Vistor Expiration Time"
                class="visitor-modal-title" v-model="visitorExpiration" />
            </div>
          </div>

          <div class="modal-create-data-item">
            <p>Visitor Email</p>
            <div>
              <input ref="visitor-email" type="text" placeholder="Visitor Email" class="visitor-modal-title"
                v-model="visitorEmail" />
            </div>
          </div>
          <div class="modal-create-data-item">
            <p>Vendor Name</p>
            <div>
              <input ref="visitor-vendor-name" type="text" placeholder="Vendor Name" class="visitor-modal-title"
                v-model="vendorName" />
            </div>
          </div>


        </div>
      </div>
      <div class="modal-footer">
        <button @click="createVisitor()">Create Visitor</button>
      </div>
    </div>
  </modal>
</template>

<script>
import * as Constants from "../../js/constants.js";
import SearchBar from "../pages/pageComps/Search.vue";

const NAME = Constants.CREATE_VISITOR_MODAL

export default {
  modalName: NAME,
  components: {
    SearchBar
  },
  watch: {
    licensePlate: function (newVal) {
      this.setLicensePlate(newVal)
    },
    visitorPhoneNumber: function (newVal) {
      this.setPhoneNumber(newVal)
    },
    visitorExpiration: function (newVal) {
      this.setExpiration(newVal)
    },
    visitorEmail: function (newVal) {
      this.setEmail(newVal)
    },
    vendorName: function (newVal) {
      this.setVendorName(newVal)
    },
    title: function (newVal) {
      this.setVisitorName(newVal)
    },
    visitorFirstName: function (newVal) {
      this.setVisitorFirstName(newVal)
    },
    visitorLastName: function (newVal) {
      this.setVisitorLastName(newVal)
    },
  },
  opened(event) {
    console.log('Opening...', event)
  },
  data() {
    return {
      modalName: NAME,
      title: "",
      useSeparateNameInput: true,
      visitorFirstName: "",
      visitorLastName: "",
      residentName: "",
      resident: null,
      displaySearchResults: false,
      searchResults: [],
      licensePlate: "",
      visitorPhoneNumber: "",
      visitorEmail: "",
      visitorExpiration: "",
      vendorName: "",
      selectedType: "",
      showTypes: false,
      types: [
        "Single Visit",
        "Frequent Visitor",
        "Vendor",
      ],
    }
  },
  methods: {
    show() {
      this.$modal.show(NAME);
    },
    beforeOpen(event) {
      console.log('Before Opening...', event)
    },
    validateKey() {
      console.log("Validating key...")
    },
    close() {
      this.$modal.hide(NAME);
    },
    performResidentSearch(query) {
      console.log("Query", query)
      let data = query;
      let localThis = this
      let url = `https://2r7ags60v2.execute-api.us-east-2.amazonaws.com/stage/nautica2/users/search?first_name=${data}&last_name=${data}&address=${data}&email=${data}&phone_number=${data}&subdivision=${data}`
      return new Promise((resolve, reject) => {
        fetch(url, {
          method: `GET`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log("Search Data", data);
            localThis.displaySearchResults = true
            localThis.searchResults = data.data
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getResidentName(resident) {
      return `${resident.first_name} ${resident.last_name}`
    },
    setVisitorName(visitorName) {
      this.title = visitorName;
      this.$refs['title'].classList.remove('invalid')
    },
    setVisitorFirstName(firstName) {
      this.visitorFirstName = firstName;
      this.$refs['visitor-first-name'].classList.remove('invalid')
    },
    setVisitorLastName(lastName) {
      this.visitorLastName = lastName;
      this.$refs['visitor-last-name'].classList.remove('invalid')
    },
    setResidentName(resident) {
      this.residentName = this.getResidentName(resident);
      this.resident = resident;
      this.displaySearchResults = false;
      this.$refs['resident'].classList.remove('invalid')
    },
    setType(type) {
      this.selectedType = type;
      this.showTypes = false;
      this.$refs['type'].classList.remove('invalid')
    },
    setLicensePlate(licensePlate) {
      this.licensePlate = licensePlate;
      this.$refs['license-plate'].classList.remove('invalid')
    },
    setPhoneNumber(phoneNumber) {
      this.visitorPhoneNumber = phoneNumber;
      this.$refs['visitor-phone-number'].classList.remove('invalid')
    },
    setExpiration(expiration) {
      this.visitorExpiration = expiration;
      this.$refs['visitor-expiration'].classList.remove('invalid')
    },
    setEmail(email) {
      this.visitorEmail = email;
      this.$refs['visitor-email'].classList.remove('invalid')
    },
    setVendorName(vendorName) {
      this.vendorName = vendorName;
      this.$refs['visitor-vendor-name'].classList.remove('invalid')
    },
    getResidentDisplayName(resident) {
      return `${resident.first_name} ${resident.last_name} (${resident.subdivision})`
    },
    inputsValid() {
      let selectedResidentVerified = this.resident != null;
      let selectedTypeVerified = this.selectedType != "";
      let selectedTitleVerified = this.title != "";
      // let selectedLicensePlate = this.licensePlate != "";
      let selectedVisitorFirstName = this.visitorFirstName != "";
      let selectedVisitorLastName = this.visitorLastName != "";
      // let selectedVisitorPhoneNumber = this.visitorPhoneNumber != "";
      // let selecetdVisitorExpirationTime = this.visitorExpiration != "";
      console.log("Selected Expiration is ", this.visitorExpiration);
      // let selectedVisitorEmail = this.visitorEmail != "";
      // let selectedVisitorVendorName = this.vendorName != "";
      let warnings = []


      if (this.useSeparateNameInput) {
        if (!selectedVisitorFirstName) {
          warnings.push("Enter visitor's First Name")
          this.$refs['visitor-first-name'].classList.add('invalid')
        }
        if (!selectedVisitorLastName) {
          warnings.push("Enter visitor's Last Name")
          this.$refs['visitor-last-name'].classList.add('invalid')
        }
      } else {
        if (!selectedTitleVerified) {
          warnings.push("Enter visitor's Name")
          this.$refs['title'].classList.add('invalid')
        }
      }
      if (!selectedTypeVerified) {
        warnings.push("Select Visitor Type")
        this.$refs['type'].classList.add('invalid')
      }
      // if (!selectedLicensePlate) {
      //   warnings.push("Input a License Plate for this Visitor")
      //   this.$refs['license-plate'].classList.add('invalid')
      // }
      // if (!selectedVisitorPhoneNumber) {
      //   warnings.push("Enter a phone number for this Visitor")
      //   this.$refs['visitor-phone-number'].classList.add('invalid')
      // }
      // if (!selecetdVisitorExpirationTime) {
      //   warnings.push("Enter an Expiration Date for the Visitor")
      //   this.$refs['visitor-expiration'].classList.add('invalid')
      // }
      // if (!selectedVisitorEmail) {
      //   warnings.push("Enter an email for the Visitor")
      //   this.$refs['visitor-email'].classList.add('invalid')
      // }
      if (!selectedResidentVerified) {
        warnings.push("Select an associated Resident for this Visitor")
        // try {
        //   this.$refs['resident'].classList.add('invalid')
        // } catch (e) {
        //   console.error(e)
        // }
        this.$refs['resident-search'].classList.add('invalid')
      }
      // if (!selectedVisitorVendorName) {
      //   warnings.push("Enter Visitor Vendor Name")
      //   this.$refs['visitor-vendor-name'].classList.add('invalid')
      // }

      if (warnings.length != 0) {
        alert(warnings.join('\n'))
        return false
      }
      return true
    },
    createVisitor() {
      if (!this.inputsValid()) {
        return;
      }

      let url = `https://2r7ags60v2.execute-api.us-east-2.amazonaws.com/stage/nautica2/keys`
      let now = new Date();
      let expirationDate;
      try {
        expirationDate = new Date(this.visitorExpiration)
      } catch {
        expirationDate = now
      }

      return new Promise((resolve, reject) => {
        fetch(url, {
          method: `POST`,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "resident_uid": this.resident.uid,
            "key_type": this.selectedType,
            "visitor_last_name": this.visitorLastName,
            "visitor_first_name": this.visitorFirstName,
            "visitor_phone_number": this.visitorPhoneNumber,
            "visitor_email": this.visitorEmail,
            "business_name": this.vendorName,
            "date_of_visit": now.getTime(),
            "time_active": now.getTime(),
            "expiration_time": expirationDate.getTime(),
            "time_expired": null,
            "visitor_relationship": "Guest",
            "business_type": "Vendor",
            "special_instructions": "Made From Gatewatch",
            "visitor_expiration_date": expirationDate.getTime(),
            "visitor_restrictions": "None",
            "incident": "",
            "incident_category": "",
            "current_status": "CREATED",
            "party_id": ""
          })
        })
          .then((res) => res.json())
          .then((data) => {
            console.log("Create Visitor from Gatewatch Data", data);
            this.close()
            resolve(data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  created() {
    console.log("In modal")
    console.log("Gate Modal Params", this.$$props)
  },
}
</script>

<style scoped>
.modal-container {
  display: flex;
  flex-direction: column;
  background-color: #699991;
  color: white;
  border-radius: 4px;
}

.modal-create-data-item input {
  flex: 1;
  height: 100%;
  width: 100%;
  font-size: 14px;
  outline: 0;
  border: 0;
  text-align: center;
}

.visitor-modal-title::placeholder {
  color: black;
}

.validate-button {
  color: white;
  background-color: #87BAB7;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  height: 2rem;
  border-radius: 5px;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.modal-field {
  padding: 1rem;
  font-size: 25px;
  border-bottom: 1px solid #87BAB7;
}

.modal-time-info {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.modal-time-inner {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-right: 1px solid #87BAB7;
  border-left: 1px solid #87BAB7;
}

.helper-text {
  text-align: left;
  font-size: 15px;
}

.circle {
  width: 160px;
  height: 160px;
  line-height: 160px;
  border-radius: 50%;
  font-size: 50px;
  color: #fff;
  text-align: center;
  background: #87BAB7;
}

.resident-search-bar {
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 12px !important;
  background-color: #F3F3F3;
  align-items: center;
  border-radius: 0px;
  border: 0;
}

.resident-search-bar::placeholder {
  color: black;
}

.resident-search-bar input {
  background-color: white;
  text-align: center;
}

.modal-header {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 4px;
  color: black;
  border: 1px solid black;
}

.modal-header h3 {
  font-size: 36px;
}

.modal-content {
  padding: 2rem;
}

.modal-intro-information {
  display: flex;
  flex-direction: column;
}

.modal-intro-information label {
  color: black;
  text-align: left;
  text-decoration: underline;
}

.modal-incident-title {
  font-size: 24px;
  margin-bottom: 18px;
  border-bottom: 1px solid black;
  text-decoration: none;
  outline: 0;
}

.modal-incident-title::placeholder {
  color: #d9d9d988;
}

.modal-incident-description {
  font-size: 14px;
  font-weight: lighter;
  border-bottom: 1px solid black;
  text-decoration: none;
  outline: 0;
  margin-bottom: 18px;
  resize: none;

}

.modal-incident-description::placeholder {
  color: #d9d9d988;
}

.modal-dropdown-button {
  color: black;
  background-color: #d9d9d9;
  padding: 4px 8px;
  margin-right: 10px;
  border: 1px solid black;
  border-radius: 4px;
}

.modal-create-data {
  margin-top: 41px;
  display: flex;
  flex-direction: column;
}

.modal-create-data-item {
  display: flex;
  flex-direction: row;
  color: black;
  border: 1px solid black;
  height: 36px;
  border-radius: 4px;
}

.modal-create-data-item input {
  flex: 1;
  background-color: #d9d9d9;
  text-align: center;
}

.modal-create-data-item p {
  align-content: center;
  text-align: left;
  font-weight: bold;
  flex: 1;
  padding-left: 8px;
  border-right: 1px solid black;
}

.modal-create-data-item div {
  flex: 1;
  background-color: #d9d9d9;
}

.modal-create-data-item button {
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: #ccd9d9d9;
}

.modal-create-data-item div ul {
  height: 100%;
}

.modal-create-data-item div li {
  position: relative;
  flex: 1;
  list-style: none;
  text-decoration: none;
  background-color: white;
  text-align: center;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-bottom: 1px solid #ccd9d9d9;

}

.modal-create-data-item div li:hover {
  background-color: #699991;
}

.invalid {
  outline: 1px solid red;
}

.modal-footer {
  padding: 1rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 4px;
  color: black;
  border: 1px solid black;
}

.modal-footer button {
  background-color: #699991;
  padding: 4px 24px;
  border-radius: 4px;
  border: 1px solid black;
  font-weight: bold;

}
</style>