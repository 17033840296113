<template>
    <div class="widget-pie-chart-container">
        <Doughnut ref="bar-chart" :chart-data="localChartData" :chartOptions="chartOptions" class="widget-pie-chart"
            :plugins="[DoughnutCenterText]" />
    </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy'
import { Chart as ChartJS, ArcElement } from 'chart.js'

const DoughnutCenterText = (message) => {
    return {
        id: 'doughnut-center-text',
        beforeDraw(chart) {
            const { ctx } = chart;
            const { top, left, width, height } = chart.chartArea;

            ctx.save();

            const centerX = left + width / 2;
            const centerY = top + height / 2;

            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.font = `16px sans-serif`; // Adjust font size as needed
            ctx.fontWeight = 'bold'

            ctx.fillText(message.split('\n')[0], centerX, centerY); // Replace with desired text
            ctx.textBaseline = 'top';
            ctx.fillText(message.split('\n')[1], centerX, centerY); // Replace with desired text

            ctx.restore();
        }
    }

};



export default {
    name: "WidgetPieChart",
    components: { Doughnut },
    props: ["chartData"],
    created() {
        console.log("Widget Pie Chart", this.chartData)
        ChartJS.register(ArcElement)

    },
    mounted() {
    },
    methods: {
        getCallback(data) {
            if (data.action == undefined || data.action == null || typeof data.action != "function") {
                return alert('Default Action')
            }
            return data.action(data)
        }
    },
    data() {
        //const labels = ['Outstanding', 'Collected']
        const dataset = [125000, 375000]
        let total = 0
        dataset.forEach(val => total += val)
        const labels = dataset.map((val, index) => {
            switch (index) {
                case 0: {
                    return `Outstanding     ${(val / total) * 100}%`
                }
                case 1: {
                    return `Collected         ${(val / total) * 100}%`

                }
            }
        })
        return {
            DoughnutCenterText: DoughnutCenterText('$500K\nTotal'),
            labels: labels,
            data: dataset,
            localChartData: {
                labels: labels,
                datasets: [{
                    data: dataset,
                    axis: 'y',
                    backgroundColor: [
                        '#BF4F4F',
                        '#87BAB7'
                    ],
                }]
            },
            chartOptions: {
                plugins: {
                    legend: {
                        position: 'right'
                    },
                    datalabels: {
                        display: true,
                        anchor: 'end',
                        align: 'end',
                        formatter: (val) => `$${val.toLocaleString('en-US')}`,
                        font: {
                            weight: 'bold',
                            size: 16
                        },
                        color: 'black'
                    }
                },
                scales: {
                    x: {
                        display: false,
                        grid: {
                            display: false
                        },
                        ticks: {
                            display: false
                        },
                        suggestedMin: 0,
                        suggestedMax: 40
                    },
                    y: {
                        display: false,
                        grid: {
                            display: false,
                            drawBorder: false
                        }
                    }
                },
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                onClick: (e, data) => {
                    console.log("clicked on ", e, data)
                    const clickedDataItem = {
                        label: this.labels[data[0].index],
                        data: this.data[data[0].index]
                    }
                    console.log("clicked on ", this.labels[data[0].index])
                    alert(JSON.stringify(clickedDataItem))
                }
            }
        }
    }
};
</script>

<style>
.widget-pie-chart-container {
    width: 100%;
    display: flex;
    height: 100%;
    max-height: 220px;
}

.widget-pie-chart {
    width: 100%;
}
</style>