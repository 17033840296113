<template>
    <div class="widget-table-item-container">
        <button v-for="data, index in tableData.data" :key="index" class="widget-table-item" @click="() => getCallback(data)">
            <img class="widget-table-item-icon" src="../../../../assets/Icons/b_gate_lg.png" width="32" height="32">
            <div class="widget-table-item-text-container">
                <h3 class="widget-table-item-title">{{ data.title }}</h3>
                <div class="widget-table-item-bottom-container">
                    <p class="widget-table-item-subtitle">{{ data.subtitle }}</p>
                    <p class="widget-table-item-details">{{ data.details }}</p>
                </div>
            </div>
        </button>
    </div>
</template>

<script>
export default {
    name: "WidgetTable",
    props: ["tableData"],
    created() {
        console.log("Widget Table", this.tableData)
    },
    methods: {
        getCallback(data) {
            if(data.action == undefined || data.action == null || typeof data.action != "function") {
                return alert('Default Action')
            }
            return data.action(data)
        }
    },
};
</script>

<style>
.widget-table-item-container {
    border-radius: 4px;
}

.widget-table-item {
    display: block;
    height: 20%;
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    border-radius: 4px;

}

.widget-table-item:hover {
    background-color: #87bab788;
}

.widget-table-item-icon {
    height: 25px;
    width: 25px;
    border-radius: 4px;
    padding: 2px;
    margin-right: 8px;
    background-color: #87bab788;
}

.widget-table-item-text-container {
    flex: 1
}

.widget-table-item-title {
    font-size: 16px;
}

.widget-table-item-bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.widget-table-item-subtitle {
    display: flex;
    font-size: 12px;
    flex: 1
}

.widget-table-item-details {
    display: flex;
    font-size: 12px;
}
</style>