<template>
  <div class="header">
    <img id="logo" alt="Community logo" src="../../assets/CTlogofiles/logohb.png" />
    <div id="userDetails">
      <SearchBar class="searchBar globalSearch" searchIcon="true" placeholder="Search Community Tech" inputStyle="font-size: 14px;"/>
      <button class="header-action-button" id="create-visitor" @click="openCreateVisitorDialog">Create Visitor</button>
      <button class="header-action-button" id="create-incident" @click="openCreateIncidentDialog">Create Incident</button>
      <div class="dropdown">
        <button class="dropbtn propertySelector">
          <div class="propertySelector">
            <p id="currentProperty">{{ currentProperty }}</p>
            <!-- <i class="fa fa-chevron-down" style="width:20px;height: 20px;font-size:20px;color:black;font-weight: 100;"></i> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-chevron-down"
              viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
            </svg>
          </div>
        </button>
        <div class="dropdown-content property">
          <SearchBar class="searchBar" searchIcon="true" placeholder="Search Communities" inputStyle="font-size: 14px;"/>
          <button v-for="property in userProperties" :key="property" class="propertyItem">
            <img class="smallIcon" src="../../assets/Icons/b_gate_lg.png">
            <a @click="setPropertyId"><button>{{ property }}</button></a>
          </button>
        </div>
      </div>

      <div class="dropdown">
        <button class="dropbtn">
          <div v-if="isUserSignedIn" class="userSignedInCard">
            <p>{{ this.userFirstName }} <br> {{ this.userLastName }}</p>
            <img alt="User Icon" src="../../assets/Icons/CTicons-04.png" />
          </div>
          <div v-else>
            <img id="user-icon" alt="User Icon" src="../../assets/Icons/CTicons-04.png" />
          </div>
        </button>
        <div class="dropdown-content">
          <div v-if="!isUserSignedIn" class="login-widget">
            <label>Email:</label>
            <input ref="userEmail" type="text" placeholder="Enter your email..." class="" v-model="userEmail" />
            <label>Password:</label>
            <input ref="userPassword" type="password" placeholder="Enter your password..." class=""
              v-model="userPassword" />
            <button @click="logIn">Log in</button>
          </div>
          <div v-else-if="showCustomDropdownLayout">
            <a href="#">My Account</a>
            <a><button @click="logOut">Log Out</button></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import * as Constants from "../../js/constants.js"
import SearchBar from "../pages/pageComps/Search.vue";

console.log("Search", SearchBar)

export default {
  name: "CTSHeader",
  components: {
    SearchBar
  },
  props: ["userData", "authClient"],
  data() {
    return {
      userId: this.$props.userData.sub,
      userName: `${this.$props.userData.name}\n${this.$props.userData.family_name}`,
      userFirstName: this.$props.userData.name,
      userLastName: this.$props.userData.family_name,
      userEmail: "",
      userEmailRef: this.$refs.userEmail,
      userPassword: "",
      userPasswordRef: this.$refs.userPassword,
      propertyList: [],
      currentProperty: this.$props.userData["custom:cts_property_id"],
      userProperties: this.$props.userData["custom:cts_property_id"] ? this.$props.userData["custom:cts_property_id"].split(':') : [
        "the_florence_1",
        "the_florence_2",
        "the_florence_3"
      ],
      group: this.$props.userData["custom:cts_group_id"],
      showCustomDropdownLayout: true,
      isUserSignedIn: false,
    };
  },
  watch: {
    userData: function (newVal, oldVal) {
      console.log("userData changed: ", newVal, " | was: ", oldVal);
      this.userId = newVal.sub;
      this.userFirstName = this.$props.userData.name
      this.userLastName = this.$props.userData.family_name
      this.userName = `${newVal.name}\n${newVal.family_name}`;
      this.userEmail = newVal.email;
      let properties = newVal["custom:cts_property_id"].split(':')
      this.currentProperty = properties[0];
      console.log("property id split", newVal["custom:cts_property_id"].split(':'))
      this.userProperties = properties
      this.group = newVal["custom:cts_group_id"];
      if (newVal.sub) {
        this.isUserSignedIn = true
      }
    },
    userEmail: function () {
      this.$refs['userEmail'].classList.remove('invalid')
    },
    userPassword: function () {
      this.$refs['userPassword'].classList.remove('invalid')
    }
  },
  methods: {
    setPropertyId(event) {
      console.log("Set Property Id", event.target);
      this.currentProperty = event.target.innerText;
    },
    openCreateVisitorDialog() {
      this.$modal.show(Constants.CREATE_VISITOR_MODAL);
    },
    openCreateIncidentDialog() {
      this.$modal.show(Constants.CREATE_INCIDENT_MODAL);
    },
    validateInput() {
      let emailRef = this.$refs['userEmail']
      let passwordRef = this.$refs['userPassword']
      let valid = true
      console.log("Password Validation", emailRef.value, passwordRef.value)
      let emailRegexPattern = /^\S+@\S+\.\S+$/
      if (emailRef.value == "" || !emailRegexPattern.test(emailRef.value)) {
        emailRef.classList.add('invalid')
        valid = false
      } else {
        emailRef.classList.remove('invalid')
      }
      if (passwordRef.value == "") {
        passwordRef.classList.add('invalid')
        valid = false
      } else {
        passwordRef.classList.remove('invalid')
      }
      return valid, emailRef.value, passwordRef.value
    },
    logIn() {
      let validInput = this.validateInput()
      console.log("Valid Input?", validInput)
      this.authClient.navigateToSignInPage()
    },
    logOut() {
      this.authClient.logout()
    }
  },
  created() {
    this.currentProperty = "the_florence_1"
    let idToken = this.authClient.getIdTokenCookie()
    idToken ? this.isUserSignedIn = true : this.isUserSignedIn = false
  }
};
</script>

<style>
.header {
  background: #fff;
  box-shadow: 0px 4px 4px 0px #699991;
  widows: 100%;
  height: 75px;
  position: sticky;
  top: 0px;
  z-index: 4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#logo {
  margin-left: 1em;
  height: 50px;
  width: 265px;
}

#userDetails {
  display: flex;
  align-items: center;
}

.header-action-button {
  font-size: 14px;
  width: 132px;
  height: 41px;
  padding: 12px 0px;
  margin-left: 8px;
  margin-right: 8px;
  flex-shrink: 0;
}

#create-visitor {
  border-radius: 7px;
  border: 1px solid #000;
  background: rgba(135, 186, 183, 0.27);
}

#create-incident {
  border-radius: 7px;
  border: 1px solid #000;
  background: rgba(255, 219, 219, 0.39);
}


#user-icon {
  height: 58px;
  width: 58px;
}


#currentProperty {
  display: inline;
  margin-right: 4px;
  padding: 4px;
  color: #000000;
  position: relative;
}

#currentProperty:hover {
  background: #d3d3d3;
  color: #000000;
  border-radius: 8px;
}

#userDropdown {
  display: inline;
  padding: 8px;
}

#userDropdown:hover {
  background: white;
  color: #87bab7;
  border-radius: 8px;
}

.dropbtn {
  color: white;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  height: 100%;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  height: 30px;
  color: black;
  text-decoration: none;
  display: block;
}

.dropdown-content a button {
  width: 100%;
  height: 100%;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.property {
  padding: 10px;
  border-radius: 8px;
}

.propertySelector {
  width: 175px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.searchBar {
  padding: 8px 8px;
  width: 256px;
  font-size: 12px!important;
  background-color: #F3F3F3;
  align-items: center;
  border-radius: 8px;
  border: 1px solid black;
}
::placeholder {
  font-weight: 100;
  color: #6E6E6E;
}

.globalSearch {
  margin-right: 4px;
}

.propertyItem {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 4px 8px 0px 8px;
  width: 100%;
  font-size: 14px;

}

.propertyItem:hover {
  background-color: #f1f1f1;
}

.propertyItem a {
  text-align: left;
  margin-left: 8px;
}


#userDropdownContent {
  height: 300px;
  min-width: 300px;
}

.login-widget {
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.login-widget label {
  text-align: left;
}

.login-widget input {
  border-bottom: 1px solid #699991;
}

.login-widget input::placeholder {
  color: #888888
}

.invalid {
  border-bottom: 1px solid red !important;
}

.login-widget button {
  margin-top: 1em;
  background-color: #699991;
  color: white;
  border-radius: 16px;
}

.userSignedInCard {
  height: 100%;
  min-width: 130px;
  padding: 16px;
  flex-direction: row;
  display: flex;
  align-items: center;
  border-left: 1px solid black;
}

.userSignedInCard p {
  min-width: 50px;
  max-height: 75px;
  color: black;
  text-align: left;
}

.smallIcon {
  height: 20px;
  width: 20px;
}

.userSignedInCard img {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: black;
  margin-left: 8px;
}
</style>