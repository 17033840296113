<template id="templateStyling">
  <div class="navMenu" id="navMenu">
    <!-- Welcome Message -->
    <div class="welcome-container">
      <div v-if="!minimized">
        <p class="welcome-name">
          {{`Hi ${this.userFirstName ?? ""}`}}
        </p>
        <p class="welcome-message">Welcome Back!</p>
      </div>
      <div v-else>
        <p class="welcome-message-minimized">R</p>
      </div>
    </div>

    <div v-bind:key="navigationItem.id" v-for="navigationItem in navigation">
      <NavItem v-bind:navigationItem="navigationItem" v-on:selectItem="emitMethod(navigationItem.title)"
        :selected="determineSelected(navigationItem.title)" :minimized="minimized" />
    </div>
    <!-- <button id="minimizeButton" @click="minimizeNavbar" name="Minimize">&lt;</button> -->
  </div>
</template>

<script>
import NavItem from "./NavItem";
import EventBus from "../eventBus";
import * as Constants from "../js/constants";

export default {
  name: "NavigationMenu",
  components: {
    NavItem,
  },
  props: ["userData", "navigation"],
  data() {
    return {
      activeItem: Constants.HOME,
      minimized: false,
      userFirstName: this.$props.userData.name,
      userLastName: this.$props.userData.family_name,
    };
  },
  watch: {
    userData: function (newVal, oldVal) {
      console.log("userData changed: ", newVal, " | was: ", oldVal);
      this.userId = newVal.sub;
      this.userFirstName = this.$props.userData.name
      this.userLastName = this.$props.userData.family_name
    }
  },
  beforeMount(){
    const path = window.location.pathname;
    console.log("Active Content.vue querystring", path);
    const filteredPath = path.replace('/','')
    this.activeItem = filteredPath
  },
  methods: {
    emitMethod(selector) {
      let navItems = Array.from(document.getElementsByClassName("active"));
      console.log("navItems", navItems);
      for (let item in navItems) {
        console.log(item, navItems[item]);
        //var element = navItems[item];

        //element.classList.remove("active");
      }
      var eventTitle = Constants.NAVIGATION_ITEM_SELECTED;
      EventBus.$emit(eventTitle, selector);
    },
    determineSelected(title) {
      let decodedActiveItem = decodeURI(this.activeItem)
      console.log("determine selected", title, decodedActiveItem, this.activeItem)
      if (decodedActiveItem.includes(title)) {
        return true;
      } else {
        return false;
      }
    },
    minimizeNavbar() {
      let div = document.getElementById("navMenu");
      let button = document.getElementById("minimizeButton");
      if (this.minimized) {
        div.classList.remove("minimized")
        button.innerHTML = "<"
        this.minimized = false
      } else {
        div.classList.add("minimized");
        button.innerHTML = ">"
        this.minimized = true

      }
    },
  },
};
</script>

<style scoped>
.navMenu {
  /* what should the dynamic scaling and min-width be set to */
  width: 250px;
  height: calc(100vh - 72px);
  flex-direction: column;
  background-color: #699991;
  position: sticky;
  padding-top: 16px;
  top: 72px;
  transition: .8s;
  float: left;
  z-index: 2;
}

.minimized {
  width: 80px;
  transition: .8s;
}

.navText {
  text-align: center;
  display: block;
  margin: auto;
}

#templateStyling {
  height: 100%;
}

#minimizeButton {
  position: absolute;
  height: 24px;
  width: 24px;
  top: 48px;
  left: calc(100% - 12px);
  background: white;
  border: 1px solid #87bab7;
  border-radius: 12px;
  color: #87bab7;
  font-size: 18px;
  text-decoration: bold;
}

.welcome-container {
  padding-left: 12px;
  color: white;
  text-align: left;
  margin-bottom: 28px;
}

.welcome-name {}

.welcome-message {
  font-weight: bold;
}
</style>