import { render, staticRenderFns } from "./WidgetTable.vue?vue&type=template&id=6534cafa"
import script from "./WidgetTable.vue?vue&type=script&lang=js"
export * from "./WidgetTable.vue?vue&type=script&lang=js"
import style0 from "./WidgetTable.vue?vue&type=style&index=0&id=6534cafa&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports