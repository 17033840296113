<template>
  <div class="navItemContainer" :id="`navItemContainer-${id}`">
    <a @click="handleClick" class="navButton" :id="id" :href="`/${navigationItem.title}`">
      <img :id="iconId" :width="iconHeight" :height="iconHeight" :src="getIcon()" />

      <p v-if="!minimized">
        {{ navigationItem.title }}
      </p>
    </a>
    <div class="nested-navigation" :id="`nested-navigation-${id}`" v-if="showNestedNavigation">
      <ul>
        <li v-for="subNavigationItem in subNavigation" :key="subNavigationItem.id">
          <img :id="iconId" :width="iconHeight" :height="iconHeight"
            :src="getSubNavigationIcon(subNavigationItem.title)" />
          <a :href="`/${navigationItem.title}/${subNavigationItem.title}`">{{ subNavigationItem.title }}</a>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
import * as Constants from "../js/constants";

export default {
  name: "NavItem",
  props: ["navigationItem", "selected", "minimized"],
  watch: {
    selected: function (newVal) {
      this.localSelected = false;
      this.handleSelection(newVal);
    },
  },
  data() {
    return {
      id: `navItem ${this.navigationItem.title}`,
      icon: `${this.navigationItem.icon}`,
      iconId: `navIcon ${this.navigationItem.title}`,
      title: this.navigationItem.title,
      activeSVG: "",
      localSelected: false,
      showNestedNavigation: false,
      iconHeight: 20, //px
      subNavigation: this.navigationItem.subNavigationItems
    };
  },
  created() { },
  mounted() {
    let item = document.getElementById(`navItemContainer-${this.id}`);
    item.addEventListener("mouseenter", () => {
      //console.log("NavItem mouseenter event", event);
      this.localSelected = true;
      this.showNestedNavigation = this.subNavigation && this.localSelected
      this.handleSelection(this.selected);
    });
    item.addEventListener("mouseleave", () => {
      //console.log("NavItem mouseleave event", event);
      this.localSelected = false;

      setTimeout(() => {
        try {
          let nestedNavigation = document.getElementById(`nested-navigation-${this.id}`)
          if (this.subNavigation && !nestedNavigation.matches(':hover')) {
            this.showNestedNavigation = this.localSelected
          }
          this.handleSelection(this.selected);
        }
        catch (e){
          console.log("Navigation Matching Error", e)
        }
      }, 50)
    });
    if (this.selected) {
      document.getElementById(this.id).classList.add("active");
    }
  },
  methods: {
    handleClick() {
      console.log("ITEM SELECTED");
      this.$emit("selectItem", this.navigationItem.title);
    },
    handleSelection(selection) {
      if (selection || this.localSelected) {
        document.getElementById(this.id).classList.add("active");
      } else {
        document.getElementById(this.id).classList.remove("active");
      }
      this.getIcon();
    },
    getIcon(icon) {
      if (this.localSelected || this.selected || this.showNestedNavigation) {
        return this.getActiveIcon(icon);
      } else {
        return this.getInactiveIcon();
      }
    },
    getSubNavigationIcon(icon) {
      return this.getActiveIcon(icon)
    },
    getActiveIcon(icon) {
      switch (icon ?? this.title) {
        case Constants.HOME: {
          return require("../assets/Icons/CTicons-02.png");
        }
        case Constants.GATE: {
          return require("../assets/Icons/CTicons-12.png");
        }
        case Constants.INCIDENTS: {
          return require("../assets/Icons/CTicons-10.png");
        }
        case Constants.ANALYTICS: {
          return require("../assets/Icons/CTicons-11.png");
        }
        case Constants.USERS: {
          return require("../assets/Icons/CTicons-04.png");
        }
        case Constants.SUPPORT: {
          return require("../assets/Icons/CTicons-05.png");
        }
        case Constants.ENTRY_LOG: {
          return require("../assets/Icons/b_contacts_lg.png")
        }
        case Constants.KEYS: {
          return require("../assets/Icons/b_gate_lg.png")
        }
        case Constants.FREQUENT_VISITORS: {
          return require("../assets/Icons/CTicons-07.png")
        }
        case Constants.BLOCKED_LIST: {
          return require("../assets/Icons/CTicons-08.png")
        }
        case Constants.VENDORS: {
          return require("../assets/Icons/CTicons-06.png")
        }
        case Constants.RESIDENT_DIRECTORY: {
          return require("../assets/Icons/b_gate_lg.png")
        }
        case Constants.VEHICLES: {
          return require("../assets/Icons/b_gate_lg.png")
        }
        case Constants.PETS: {
          return require("../assets/Icons/b_gate_lg.png")
        }
        case Constants.COMMUNITYANDCOMMUNICATION: {
          return require("../assets/Icons/CTicons-12.png")
        }
        case Constants.ACCOUNTING: {
          return require("../assets/Icons/CTicons-11.png");
        }
        case Constants.MAINTENANCE: {
          return require("../assets/Icons/CTicons-05.png");
        }
      }
    },
    getInactiveIcon() {
      switch (this.title) {
        case Constants.HOME: {
          return require("../assets/Icons/CTiconsw-02.png");
        }
        case Constants.GATE: {
          return require("../assets/Icons/CTiconsw-12.png");
        }
        case Constants.INCIDENTS: {
          return require("../assets/Icons/CTiconsw-10.png");
        }
        case Constants.ANALYTICS: {
          return require("../assets/Icons/CTiconsw-11.png");
        }
        case Constants.USERS: {
          return require("../assets/Icons/CTiconsw-04.png");
        }
        case Constants.COMMUNITYANDCOMMUNICATION: {
          return require("../assets/Icons/CTiconsw-12.png");
        }
        case Constants.ACCOUNTING: {
          return require("../assets/Icons/CTiconsw-11.png");
        }
        case Constants.MAINTENANCE: {
          return require("../assets/Icons/CTiconsw-05.png");
        }
        case Constants.SUPPORT: {
          return require("../assets/Icons/CTiconsw-05.png");
        }
      }
    },
  },
};
</script>

<style scoped>
.navItemContainer {
  display: flex;
  flex-direction: row;
  overflow-x: visible;
  width: 100%;
  flex-wrap: nowrap;
}

.navButton {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  background-color: #699991;
  color: white;
  border-radius: 4px;
  text-decoration: none;
}

.navButton:hover {}

.navButton p {
  display: inline;
  text-align: left;
  flex: 1;
  font-size: 14px;
}

.navButton img {
  margin-right: 8px;
  margin-left: 8px;
}

.active {
  background-color: white;
  color: black;
}

.nested-navigation {
  display: inline;

  position: absolute;
  left: 100%;
  width: auto;
  margin-left: 14px;
  float: right;
  z-index: 3
}

.nested-navigation ul {
  border-radius: 12px;
  padding-left: 12px;
  padding-right: 12px;
  background-clip: padding-box;
  background: #87bab7;
  color: white;
  text-align: center;
  text-decoration: none;
  list-style: none;
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;
  justify-content: center;
  position: absolute;;
  z-index: 3

}

.nested-navigation ul li {
  color: black;
  width: auto;
  min-width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 15px;
  text-align:left;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  position: sticky;
  z-index: 3


}

.nested-navigation ul li img{
  position: relative;
  display: flex;
  height: 20px;
  margin-right: 8px;
  margin-left: 4px;
}
.nested-navigation ul li a{
  display: flex;
  align-self: center;
  height: 20px;
  padding-right: 4px;
  color: black;
  text-decoration: none;
}

.nested-navigation ul li:hover {
  color: black;
  background: white;
  border-radius: 4px;
}
</style>