export const HOME = "Dashboard"
export const GATE = "Visitor Management"
export const ENTRY_LOG = "Entry Log"
export const KEYS = "Keys"
export const FREQUENT_VISITORS = "Frequent Visitors"
export const BLOCKED_LIST = "Blocked List"
export const VENDORS = "Vendors"
export const INCIDENTS = "Incident Management"
export const ANALYTICS = "Reporting"
export const USERS = "Directory"
export const RESIDENT_DIRECTORY = "Resident Directory"
export const VEHICLES = "Vehicles"
export const PETS = "Pets"
export const SUPPORT = "Settings"
export const MAINTENANCE = "Maintenance"
export const COMMUNITYANDCOMMUNICATION = "Community & Communication"
export const ACCOUNTING = "Accounting"
export const NAVIGATION_ITEM_SELECTED = "navigationItemSelected";
export const ACTION_INVALID = "This action is invalid!"
export const CREATE_VISITOR_MODAL = "create-visitor-modal"
export const CREATE_INCIDENT_MODAL = "create-incident-modal"
export const INCIDENT_MODAL = "incident-modal"
export const UPDATE_INCIDENT_MODAL_DATA = "update-incident-modal-data"
export const DEFAULT_CARD_INFO = [
    {
        title: "Total Keys Sent",
        value: "70",
        filters: {

        }
    },
    {
        title: "Total Keys Validated",
        value: "30",
        filters: {
            residentName: "",
            visitorName: "",
            subdivision: "",
            status: "VALIDATED",
            startTime: null,
            endTime: null
        }
    },
    {
        title: "Total Keys Pending",
        value: "3",
    },
    {
        title: "Total Keys Denied",
        value: "1",
    },
    {
        title: "Party Keys Created",
        value: "30",
        filters: {
            residentName: "",
            visitorName: "",
            subdivision: "",
            status: "CREATED",
            startTime: null,
            endTime: null
        }
    }
]