<template>
  <div class="activeContent">
    <div v-if="currentActiveContent == home">
      <Dashboard :userData="userData"/>
    </div>
    <div v-else-if="currentActiveContent == gate">
      <Gate />
    </div>
    <div v-else-if="currentActiveContent == incidents">
      <Incidents />
    </div>
    <div v-else-if="currentActiveContent == analytics">
      <Reporting />
    </div>
    <div v-else-if="currentActiveContent == users">
      <Users />
    </div>
    <div v-else-if="currentActiveContent == maintenance">
      <Maintenance />
    </div>
    <div v-else-if="currentActiveContent == accounting">
      <Accounting/>
    </div>
    <div v-else-if="currentActiveContent == communications">
      <Communications/>
    </div>
    <div v-else-if="currentActiveContent == support">
      <Settings/>
    </div>
  </div>
</template>

<script>
import Dashboard from "./pages/Dashboard";
import Gate from "./pages/Gate.vue";
import EventBus from "../eventBus";
import * as Constants from "../js/constants";
import Reporting from "./pages/Reporting.vue";
import Incidents from "./pages/Incidents.vue";
import Users from "./pages/Users.vue"
import Maintenance from "./pages/Maintenance.vue";
import Accounting from "./pages/Accounting.vue";
import Communications from "./pages/Communications.vue";
import Settings from "./pages/Settings.vue";

export default {
  name: "ActiveContent",
  components: {
    Dashboard,
    Gate,
    Reporting,
    Incidents,
    Users,
    Maintenance,
    Accounting,
    Communications,
    Settings
  },
  props:['userData'],
  data() {
    return {
      currentActiveContent: Constants.HOME,
      home: Constants.HOME,
      gate: Constants.GATE,
      incidents: Constants.INCIDENTS,
      analytics: Constants.ANALYTICS,
      users: Constants.USERS,
      maintenance: Constants.MAINTENANCE,
      accounting: Constants.ACCOUNTING,
      communications: Constants.COMMUNITYANDCOMMUNICATION,
      support: Constants.SUPPORT,
    };
  },
  created() {
    var eventTitle = Constants.NAVIGATION_ITEM_SELECTED;
    EventBus.$on(eventTitle, (data) => {
      this.showContent(data);
    });
    const path = window.location.pathname;
    console.log("Active Content.vue querystring", path);
    const filteredPath = path.replace('/', '')
    this.showContent(filteredPath)
  },
  methods: {
    showContent(data) {
      console.log("Show Content:", data);
      window.scrollTo(0, 0)
      let pathToNavigate
      if (data.includes('/')) {
        pathToNavigate = data.substring(0, data.indexOf('/'))
      } else {
        pathToNavigate = data
      }
      pathToNavigate = decodeURI(pathToNavigate)
      console.log("pathToNavigate data", data)
      console.log("pathToNavigate", pathToNavigate)
      switch (pathToNavigate) {
        default: {
          this.showPage(pathToNavigate);
          break;
        }
      }
    },
    showPage(path) {
      console.log("Active Content", `Show ${path}`)
      let finalPath;
      if(path == "" || path == null) {
        finalPath = Constants.HOME
      } else {
        finalPath = path
      }
      this.currentActiveContent = finalPath;
    },
    showSupport() {
      console.log("Active Content", "Show Users");
      let url = "https://communitytech-support.atlassian.net/servicedesk/customer/portals"
      window.open(url, '_blank').focus();
    },
  },
};
</script>

<style scoped>
.activeContent {
  width: 100%;
  height: 100%;
  padding-left: 32px;
  padding-right: 32px;
  background-color: #f2f2f2;
  display: block;
}
</style>