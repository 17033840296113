<template>
    <div class="widget-bar-chart-container">
        <Bar ref="bar-chart" :chart-data="localChartData" :chartOptions="chartOptions" class="widget-bar-chart" />
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)


export default {
    name: "WidgetBarChart",
    components: { Bar },
    props: ["chartData"],
    created() {
        console.log("Widget Bar Chart", this.chartData)
    },
    methods: {
        getCallback(data) {
            if (data.action == undefined || data.action == null || typeof data.action != "function") {
                return alert('Default Action')
            }
            return data.action(data)
        }
    },
    data() {
        const labels = ['Urgent', 'Medium', 'Low']
        const dataset = [20, 10, 30]
        return {
            labels: labels,
            data: dataset,
            localChartData: {
                labels: labels,
                datasets: [{
                    data: dataset,
                    axis: 'y',
                    backgroundColor: [
                        '#BF4F4F',
                        '#DAC400',
                        '#87BAB7'
                    ],
                    borderSkipped: false,
                    borderRadius: 6
                }]
            },
            chartOptions: {
                plugins: {
                    legend: {
                        display: false
                    },
                    datalabels: {
                        display: true,
                        anchor: 'end',
                        align: 'end',
                        font: {
                            weight: 'bold',
                            size: 16
                        },
                        color: 'black'
                    }
                },
                scales: {
                    x: {
                        display: false,
                        grid: {
                            display: false
                        },
                        ticks: {
                            display: false
                        },
                        suggestedMin: 0,
                        suggestedMax: 40
                    },
                    y: {
                        grid: {
                            display: false,
                            drawBorder: false
                        }
                    }
                },
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                onClick: (e, data) => {
                    console.log("clicked on ", e, data)
                    const clickedDataItem = {
                        label: this.labels[data[0].index],
                        data: this.data[data[0].index]
                    }
                    console.log("clicked on ", this.labels[data[0].index])
                    alert(JSON.stringify(clickedDataItem))
                }
            }
        }
    }
};
</script>

<style>
.widget-bar-chart-container {
    width: 100%;
    display: flex;
    height: 100%;
    max-height: 220px;
}

.widget-bar-chart {
    width: 100%;
}
</style>