<template>
  <div class="camsContainer">
    <div class="nonGraphContainer">
      <div class="pageHeader">
        <ContentHeader :name=this.headerName :subHeader="subHeader" />
        <button @click="(event) => handleButtonPress(event)">New Work Order</button>
      </div>
      <Insights :cardInfo="cardInfo" :callback="updateFiltersForSearchBasedOnCard" />
    </div>


    <div class="currentView">
      <div>
        <div class="dataTableSelectorGroup">
          <div class="dataTableSelector">
            <SearchBar class="gate-search" v-bind:search="search"
              v-bind:searchCallback="(data) => { this.performSearch(data) }" placeholder="Enter Search" />
            <svg class="filterIcon" width="32" height="26" viewBox="0 0 32 26" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="4" fill="#699991" rx="2" />
              <rect x="8" y="22" width="16" height="4" fill="#699991" rx="2" />
              <rect x="4" y="11" width="24" height="4" fill="#699991" rx="2" />
            </svg>
          </div>
        </div>
        <DataTable v-bind:tableData="tableData" v-bind:configuration="selectedItem" v-bind:openModalCallback="() => {
          handleModal(selectedItem);
        }
          " />
      </div>
    </div>
  </div>
</template>

<script>
import ContentHeader from "../ContentHeader";
import * as Constants from "../../js/constants";
import DataTable from "./pageComps/DataTable.vue";
import SearchBar from "./pageComps/Search.vue";
import Insights from "../layout/Insights.vue";

export default {
    components: {
        ContentHeader,
        DataTable,
        SearchBar,
        Insights
    },
    data() {
        return {
            selectedItem: Constants.INCIDENTS,
            headerName: Constants.MAINTENANCE,
            filters: [],
            tableData: [],
            search: "",
            cardInfo: Constants.DEFAULT_CARD_INFO
        };
    },
    created() {
        this.performSearch("Richard").then((apiData) => {
            console.log("Finished getting and setting API Data", apiData);
        });
    },
    methods: {
        performSearch(data) {
            this.search = data
            let url = `https://2r7ags60v2.execute-api.us-east-2.amazonaws.com/stage/nautica2/incidents`
            return new Promise((resolve, reject) => {
                fetch(
                    url,
                    {
                        method: `GET`,
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((data) => {
                        console.log("Search Data", data);
                        this.tableData = data.data
                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
};
</script>

<style scoped>
.dataTableSelectorGroup {
    display: flex;
    widows: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
}

.dataTableSelector:nth-child(3n) {
    margin-right: 0px;
}

.selectorContent {
    width: 100%;
    color: white;
    font-size: 28px;
    display: inline;
    margin-right: 16px;
}

.selectorContent::placeholder {
    width: 100%;
    color: white;
    font-size: 28px;
}
.pageHeader button {
    background-color: #1890FFCC;
    color: white;
}

.filterIcon {
    margin-right: 16px;
    display: inline;

}

.iconGroup {
    display: inline;
}
</style>