<template>
  <div class="header-container">
      <h2 class="header-title">{{ name }}</h2>
      <p class="subHeader">{{ subHeader }}</p>
  </div>
</template>

<script>
import EventBus from "../eventBus.js";
export default {
  name: "ContentHeader",
  props: ["name", "subHeader"],
  data() {
    return {
      id: 1,
      isActive: true,
      title: "Home", // this will eventually be dynamic and be based off the page we are currently on...will probably get kicked off by EventBus
    };
  },
  components: {},
  methods: {
    emitMethod(selector) {
      EventBus.$emit(selector, selector);
      if (selector == "keys") {
        this.isActive = !this.isActive;
      } else {
        this.isActive = !this.isActive;
      }
    },
  },
  created() {},
};
</script>

<style scoped>
  .header-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    border-radius: 4px;
  }
  .header-title {
    color: #000000;
    font-size: 24px;
    padding: 0px;
    margin-top: 0px;
  }
  .subHeader {
    color: #000000;
    font-size: 14px;
    padding: 0px;
    margin-top:18px;
    margin-bottom: 16px;
  }
  
</style>