<template>
  <button class="indv_card" v-on:click="() => filtersAction(card)">
    <h2 id="cardVal" class="cardValue">{{ card.value }}</h2>
    <p class="cardTitle">{{ card.title }}</p>
  </button>
</template>

<script>
export default {
  name: "InsightsCard",
  components: {},
  props: ["card", "callback"],
  methods: {
    setWarning() {
      var sentiment = this.card.sentiment;
      console.log(sentiment);
      var element1 = document.getElementById("cardVal");
      var element2 = document.getElementById("subTrend");
      if (sentiment == "positive") {
        //leave white
      } else if (sentiment == "warning") {
        element1.classList.add("warning");
        element2.classList.add("warning");
        console.log("Adding Warning Class");
      } else if (sentiment == "alert") {
        element1.classList.add("alert");
        element2.classList.add("alert");
        console.log("Adding Alert Class");
      } else {
        console.log("INVALID SENTIMENT DETECTED");
      }
    },
    filtersAction(card) {
      //alert(`${card.title} - Updating Table Filters`)
      this.$props.callback(card)
    }
  },
};
</script>

<style>
/* sentiments are positive, warning, and alert */

.warning {
  color: #dcde8a;
}

.alert {
  color: #e9415f;
  opacity: 70%;
}

.cardTitle {
  text-align: left;
}

.cardValue {
  font-size: 20px;
}

.subtitleTrend {
  padding-right: 10px;
  padding-bottom: 10px;
  font-style: italic;
  text-align: right;
}

.indv_card {
  background: white;
  width: 100%;
  padding-left: 20%;
  padding-top: 20px;
  padding-bottom: 20px;
  color: black;
  display: flex;
  font-size: 12px;
  align-items: start;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>