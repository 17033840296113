
<template>
    <div id="insights_container">
        <div id="cards_container">
                <div v-for="card in cardInfo" :key ="card.insight_id">
                    <InsightsCard class="Ins_card" v-bind:card="card" :callback="callback"></InsightsCard>
                </div>
        </div>
    </div>
</template>


<script>
import InsightsCard from './InsightsCard'

export default {
    name: "InsightsModule",
    props:['cardInfo', 'callback'],
    components: {
        InsightsCard
    },

    

}
</script>

<style>
#cards_container
{
    /* padding: 30px; */
    width: 100%;
    display: grid;
    grid-template-columns: 18% 18% 18% 18% 18%;
    margin-bottom: 15px;
    justify-content: space-between;
}


</style>